function ERROR400() {
	return (
		<>
			<h1>ERROR 400</h1> <p>Bad Request</p>
		</>
	);
}

function ERROR401() {
	return (
		<>
			<h1>ERROR 401</h1> <p>Unauthorized</p>
		</>
	);
}

function ERROR403() {
	return (
		<>
			<h1>ERROR 403</h1> <p>Forbidden</p>
		</>
	);
}

function ERROR404() {
	return (
		<>
			<h1>ERROR 404</h1> <p>Page Cannot Be Found</p>
		</>
	);
}

function ERROR418() {
	return (
		<>
			<h1>ERROR 418</h1> <p>Im a teapot go find a coffee machine</p>
		</>
	);
}

function ERROR500() {
	return (
		<>
			<h1>ERROR 500</h1> <p>Internal Server Error</p>
		</>
	);
}

function ERROR502() {
	return (
		<>
			<h1>ERROR 502</h1> <p>Bad Gateway</p>
		</>
	);
}

function ERROR503() {
	return (
		<>
			<h1>ERROR 503</h1> <p>Service Unavailable</p>
		</>
	);
}

export {
	ERROR400,
	ERROR401,
	ERROR403,
	ERROR404,
	ERROR418,
	ERROR500,
	ERROR502,
	ERROR503,
};
