import { LinkURL } from "../components/links";

export default function Archive() {
    return (
        <div>
            <div className="the_intro">
                <h1>The Archive</h1>
                <div className="info">
                    The Archive is a place where you can view my old projects
                    that i used to work on. They no longer really get updated
                    but they are cool to see what i used to do, and the progress
                    i have made.
                </div>
            </div>
            <section className="contact_block">
                <h2>Old Site Versions</h2>
                <p>This is the current site (Version 3)</p>
                <p>
                    There are many improvements from the original version to
                    this one. some of the Version 2 features will be missed
                    though as they were for fun.
                </p>
                <div className="links">
                    <LinkURL
                        href="https://archive.radquincy.com/site_version_1"
                        name="Version 1"
                    />
                    <LinkURL
                        href="https://archive.radquincy.com/site_version_2"
                        name="Version 2"
                    />
                </div>
            </section>
            <section className="contact_block">
                <h2>Rad Games</h2>
                <p>
                    The Old development version coded entirely in JS, HTML and SCSS.
                </p>
                <div className="links">
                    <LinkURL
                        href="https://archive.radquincy.com/rad_games"
                        name="Rad Games Version 1"
                    />
                </div>
            </section>
            <section className="contact_block">
                <h2>School Work</h2>
                <p>
                    Old School Tutorials Made using PHP. They are pretty slow
                    and simple in comparison to what i do now. But this is where
                    i truly started programming.
                </p>

                <div className="links">
                    <LinkURL
                        href="https://archive.radquincy.com/computing_units_1_and_2"
                        name="Applied Computing Units 1 & 2"
                    />
                </div>
            </section>
        </div>
    );
}
