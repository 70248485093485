import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import {
    ERROR400,
    ERROR401,
    ERROR403,
    ERROR404,
    ERROR418,
    ERROR500,
    ERROR502,
    ERROR503,
} from "./components/error_pages";

import Home from "./pages/home";
import Resources from "./pages/resources";
import AllProjects from "./pages/all_projects";
import WhatsNext from "./pages/whats_next";
import Info from "./pages/info";
import Archive from "./pages/archive";
// import Blog from "./pages/blog";

import "./main.css";
import "./dark_theme.css";

const titles = {
    "/": "Home",
    "/home": "Home.",
    "/resources": "Resources",
    "/whats_next": " What's Next",
    "/info": "Info",
    "/all_projects": "All Projects",
    "/archive": "Archive",
    "/error/400": "Error 400",
    "/error/401": "Error 401",
    "/error/403": "Error 403",
    "/error/404": "Error 404",
    "/error/418": "Error 418",
    "/error/500": "Error 500",
    "/error/502": "Error 502",
    "/error/503": "Error 503",
};
function App() {
    const location = useLocation();
    useEffect(() => {
        const loadTitle = async () => {
            document.title = titles[location.pathname]
                ? `radquincy.com - ${titles[location.pathname]}`
                : "radquincy.com - Page Not Found";
        };
        loadTitle();
    }, [location]);
    return (
        <div className="page_size">
            <Routes>
                <Route path="*" element={<ERROR404 />} />
                <Route path="/" element={<Home />} />

                <Route path="/home" element={<Home />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/whats_next" element={<WhatsNext />} />
                <Route path="/all_projects" element={<AllProjects />} />
                <Route path="/info" element={<Info />} />
                <Route path="/archive" element={<Archive />} />
                {/* <Route  path="/blog" element={<Blog />}/> */}
                {/* <Route  path="/blog/:id" element={<Blog />}/> */}

                {/* //. ERROR CODES */}
                <Route path="/error/400" element={<ERROR400 />} />
                <Route path="/error/401" element={<ERROR401 />} />
                <Route path="/error/403" element={<ERROR403 />} />
                <Route exact path="/error/404" element={<ERROR404 />} />
                <Route exact path="/error/418" element={<ERROR418 />} />
                <Route exact path="/error/500" element={<ERROR500 />} />
                <Route exact path="/error/502" element={<ERROR502 />} />
                <Route exact path="/error/503" element={<ERROR503 />} />
            </Routes>
        </div>
    );
}

export default App;
