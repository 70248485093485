import ProgressBar from "../components/progress_bar/progress_bar";
import { LinkURL } from "../components/links";
export default function WhatsNext() {
    return (
        <div>
            <h1>Whats Next</h1>
            <h2>Workbook</h2>
            <p>
                Workbook is a project that uses markdown syntax to save and view
                files. Files over the internet allows for much easier sharing
                and accessibility to users.
            </p>
            <ProgressBar from="Nothing" to="Beta Release" progress="55" />
            <div className="links">
                <LinkURL href="https://workbook.radquincy.com/" name="Visit" />
            </div>

            <h2>Extra Origins Ascended</h2>
            <p>
                A minecraft data pack for the origins mod. Its adds new powerful
                abilities for the Extra Origins Mod origins, allowing them to be
                as powerful as ever once Ascended.
            </p>
            <ProgressBar from="Nothing" to="Main Release" progress="65" />

            <h2>Rad Games Recode</h2>
            <p>
                The Rad Games is a game project i try to keep up to date for
                fun. The project is getting an update to allow for easier
                updates and better performance.
            </p>
            <ProgressBar from="Nothing" to="First Release" progress="80" />
            <ProgressBar from="First Release" to="Account Functionality" progress="20" />
        </div>
    );
}
