import "../home.css";
import React from "react";

function ProjectCard(pass) {
    return (
        <div className="project">
            <div className="img_box" id="card">
                <img alt={pass.img_alt} src={pass.img_src} />
                <a
                    alt={pass.a_alt}
                    href={pass.a_href}
                    title={pass.a_title}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button>{pass.btn_text}</button>
                </a>
            </div>
            <div className="text_box">
                <h3>{pass.title}</h3>
                {pass.text}
            </div>
        </div>
    );
}

function TheIntro() {
    return (
        <section className="the_intro">
            <p className="intro">Hey!</p>
            <p className="intro">you can call me,</p>
            <p className="intro">Rad_Quincy</p>
            <p className="info">
                I'm a learning website designer! I prefer front end design, but
                am also do full stack development.
            </p>
            <div className="shape"></div>
        </section>
    );
}

function Projects() {
    return (
        <section className="project_block">
            <h2>Projects</h2>
            <p>I have worked on quite a few projects.</p>
            <div className="projects">

                <ProjectCard
                    img_alt=""
                    img_src="https://images.unsplash.com/photo-1579373903781-fd5c0c30c4cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                    a_href="https://radgames.radquincy.com/"
                    btn_text="Visit"
                    title="Rad Games"
                    text="A small fun game project i work on. It
                    adds small new unique games to the browser for you to
                    play. UPDATED!"
                />

                <ProjectCard
                    img_alt=""
                    img_src="https://images.unsplash.com/photo-1501949997128-2fdb9f6428f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                    a_href="./"
                    a_title="Bancoora SLSC (not my site currently)"
                    btn_text="Not Finished"
                    title="Bancoora SLSC"
                    text="A site built for a volunteer Surf
                    Life Saving Club who aim at safety and saving lives at
                    and around the beach. (not finished)"
                />

                {/* <ProjectCard
                    img_alt="workbook"
                    img_src="https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                    a_href="https://workbook.radquincy.com/"
                    btn_text="Visit"
                    title="Work Book"
                    text="My Biggest project so far, but is in progress. It will
                    allow for easy use between platforms and purposes. (not
                    finished)"
                /> */}


                {/* <ProjectCard
                    img_alt=""
                    img_src="https://images.unsplash.com/photo-1614680376739-414d95ff43df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                    a_href="./"
                    btn_text="New Page Soon"
                    title="Phrog the Bot"
                    text="A Discord Bot aimed at allowing
                    easier use to discord compared to other bots in the
                    community."
                /> */}
            </div>
        </section>
    );
}

function ContactAndProjects() {
    return (
        <>
            <section className="contact_block">
                <h2>Socials & Contacts</h2>
                <p>Check out more of my stuff or contact me</p>
                <div className="contacts">
                    <a
                        className="contact"
                        href="https://github.com/radquincy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ion-icon name="logo-github"></ion-icon>
                        <p>Rad_Quincy</p>
                        <p className="sub_txt">radquincy</p>
                    </a>

                    <a
                        className="contact"
                        href="https://codepen.io/rad_quincy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ion-icon name="logo-codepen"></ion-icon>
                        <p>Rad_Quincy</p>
                        <p className="sub_txt">@rad_quincy</p>
                    </a>

                    <a
                        className="contact"
                        href="https://stackoverflow.com/users/21669239"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ion-icon name="logo-stackoverflow"></ion-icon>
                        <p>Rad_Quincy</p>
                        <p className="sub_txt">21669239</p>
                    </a>

                    <a
                        className="contact"
                        href="https://discord.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ion-icon name="logo-discord"></ion-icon>
                        <p>@rad_quincy</p>
                        <p className="sub_txt">Rad_Quincy</p>
                    </a>

                    <a
                        className="contact"
                        href="mailto: hello@radquincy.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ion-icon name="mail-outline"></ion-icon>
                        <p>hello@radquincy.com</p>
                    </a>
                </div>
            </section>

            <section className="contact_block">
                <h2>My Tech Stack</h2>
                <p>What technology am i good at.</p>
                <div className="contacts">
                    <div className="contact">
                        <ion-icon name="logo-html5"></ion-icon>
                        <p>HTML</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="logo-css3"></ion-icon>
                        <p>CSS</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="logo-javascript"></ion-icon>
                        <p>JavaScript</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="globe-outline"></ion-icon>
                        <p>TypeScript</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="logo-react"></ion-icon>
                        <p>React JS</p>
                    </div>
                    <div className="contact">
                        <ion-icon name="logo-sass"></ion-icon>
                        <p>SCSS / Sass</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="globe-outline"></ion-icon>
                        <p>PHP</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="analytics-outline"></ion-icon>
                        <p>MySQL</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="logo-markdown"></ion-icon>
                        <p>Markdown</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="logo-nodejs"></ion-icon>
                        <p>Node JS</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="color-fill-outline"></ion-icon>
                        <p>Illustrator</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="image-outline"></ion-icon>
                        <p>Photoshop</p>
                    </div>
                </div>
            </section>

            <section className="contact_block">
                <h2>Tech i am learning</h2>
                <p>
                    Technology i am learning to improve my skills and abilities.
                </p>
                <div className="contacts">
                    <div className="contact">
                        <ion-icon name="logo-electron"></ion-icon>
                        <p>Electron JS</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="logo-firebase"></ion-icon>
                        <p>Firebase</p>
                    </div>

                    <div className="contact">
                        <ion-icon name="logo-python"></ion-icon>
                        <p>Python</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default function Home() {
    return (
        <div>
            <TheIntro />
            <Projects />
            <ContactAndProjects />
        </div>
    );
}
