const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

const currentTheme = localStorage.getItem("theme");
if (currentTheme === "dark") {
	console.log("load dark theme");
	document.body.classList.toggle("dark-theme");
} else if (currentTheme === "light") {
	console.log("load light theme");
	document.body.classList.toggle("light-theme");
}

export default function ThemeToggleBtn() {
	let theme = "";
	if (localStorage.getItem("theme") === null) {
		if (prefersDarkScheme.matches) {
			document.body.classList.toggle("dark-theme");
			theme = "dark";
			console.log("no theme prefers dark theme");
		} else {
			document.body.classList.toggle("light-theme");
			theme = "light";
			console.log("no theme prefers light theme");
		}
	}
	const switch_theme = () => {
		if (localStorage.getItem("theme") === "dark") {
			document.body.classList.toggle("dark-theme");
			document.body.classList.toggle("light-theme");
			theme = document.body.classList.contains("dark-theme")
				? "dark"
				: "light";
		} else {
			document.body.classList.toggle("light-theme");
			document.body.classList.toggle("dark-theme");
			theme = document.body.classList.contains("light-theme")
				? "light"
				: "dark";
		}

		localStorage.setItem("theme", theme);
	};
	return (
		<button onClick={switch_theme} className="icon_button">
			<ion-icon name="invert-mode-outline"></ion-icon>
		</button>
	);
}
