import { LinkURL } from "../../components/links";
import { TabContent, TabNavItem } from "../../components/tabs";
import { useState } from "react";

function IconTab() {
    return (
        <>
            <h2>Icons</h2>
            <div className="links">
                <LinkURL
                    href="https://icons.getbootstrap.com/"
                    name="Bootstrap Icons"
                />

                <LinkURL href="https://ionic.io/ionicons" name="Ion Icons" />

                <LinkURL
                    href="https://feathericons.com/"
                    name="Feather Icons"
                />

                <LinkURL
                    href="https://fonts.google.com/icons?query=arial"
                    name="Google Icons"
                />

                <LinkURL
                    href="https://remixicon.com/"
                    name="Remix Icons"
                    new={false}
                />
                <LinkURL
                    href="https://boxicons.com/"
                    name="Box Icons"
                    new={false}
                />
                <LinkURL
                    href="https://css.gg/"
                    name="CSS Icons"
                    new={true}
                />
            </div>
        </>
    );
}

function AnimationTab() {
    return (
        <>
            <h2>CSS</h2>
            <div className="links">
                <h3>Animation Generators</h3>
                <LinkURL
                    href="https://webcode.tools/generators/css/keyframe-animation"
                    name="Web Code Tools"
                />
                <LinkURL href="https://keyframes.app/" name="Keyframes" />
                <LinkURL href="https://animista.net/" name="Animista" />

                <h3>Calculators</h3>
                <LinkURL
                    href="https://ratiobuddy.com/"
                    name="Ratio Buddy"
                    new={false}
                />
                <LinkURL
                    href="https://nekocalc.com/px-to-rem-converter"
                    name="nekoCalc"
                    new={false}
                />
                <LinkURL href="https://cubic-bezier.com/" name="Cubic Bezier" />

                <h3>Resources</h3>
                <LinkURL
                    href="https://www.cssportal.com/"
                    name="CSS Portal"
                    new={false}
                />
            </div>
        </>
    );
}

function ColorTab() {
    const [activeTab, setActiveTab] = useState("0");

    return (
        <>
            <h2>Colors</h2>

            <div className="links_with_box">
                <div className="links">
                    <TabNavItem
                        title="coolors"
                        id="0"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />

                    <TabNavItem
                        title="Adobe Color"
                        id="1"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />

                    <TabNavItem
                        title="Realtime Colors"
                        id="2"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />

                    <TabNavItem
                        title="Brand Colors"
                        id="3"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                </div>

                <div className="card">

                    <TabContent id="0" activeTab={activeTab}>
                        <h3>coolors</h3>
                        <p></p>
                        <div className="links"><LinkURL href="https://coolors.co/" name="Visit coolors" /></div>
                    </TabContent>

                    <TabContent id="1" activeTab={activeTab}>
                        <h3>Adobe Color</h3>
                        <p></p>
                        <div className="links"><LinkURL
                            href="https://color.adobe.com/create"
                            name="Visit Adobe Color"
                        /></div>
                    </TabContent>

                    <TabContent id="2" activeTab={activeTab}>
                        <h3>Realtime Colors</h3>
                        <p></p>
                        <div className="links">                <LinkURL
                            href="https://realtimecolors.com/"
                            name="Visit Realtime Colors"
                        /></div>
                    </TabContent>

                    <TabContent id="3" activeTab={activeTab}>
                        <h3>Brand Colors</h3>
                        <p></p>
                        <div className="links"><LinkURL
                            href="https://brandcolors.net/"
                            name="Visit Brand Colors"
                        /></div>
                    </TabContent>
                </div>
            </div>
        </>
    );
}

function FontTab() {
    const [activeFontTab, setActiveFontTab] = useState("google_fonts");

    return (
        <>
            <h2>Fonts</h2>

            <div className="links_with_box">
                <div className="links">
                    <TabNavItem
                        title="Google Fonts"
                        id="google_fonts"
                        activeTab={activeFontTab}
                        setActiveTab={setActiveFontTab}
                    />


                    <TabNavItem
                        title="Font Brief"
                        id="font_brief"
                        activeTab={activeFontTab}
                        setActiveTab={setActiveFontTab}
                    />
                </div>

                <div className="card">

                    <TabContent id="google_fonts" activeTab={activeFontTab}>
                        <h3>Google Fonts</h3>
                        <p></p>
                        <div className="links"><LinkURL href="https://fonts.google.com/" name="Visit Google Fonts" /></div>
                    </TabContent>

                    <TabContent id="font_brief" activeTab={activeFontTab}>
                        <h3>Font Brief</h3>
                        <p></p>
                        <div className="links">                <LinkURL
                            href="https://www.fontbrief.com/fontbrief"
                            name="Font Brief"
                        /></div>
                    </TabContent>
                </div>
            </div>
        </>
    );

}

function VectorGraphicTab() {
    return (
        <>

        </>
    );
}

function ImageryTab() {
    return (
        <>

            <h2>Imagery</h2>
            <div className="links">
                <h3>Raster</h3>
                <LinkURL href="https://placekitten.com/" name="Place Kitten" />
                <LinkURL href="https://shots.so/" name="Shots" />
                <LinkURL href="https://unsplash.com/" name="Unsplash" />
                <LinkURL href="https://www.pixilart.com/" name="Pixilart" />
                <LinkURL href="https://favicon.io/" name="favicon.io" />
                <LinkURL
                    href="https://www.websiteplanet.com/webtools/favicon-generator/"
                    name="Website Planet"
                />

                <h3 >Vector</h3>
                <LinkURL href="https://haikei.app/" name="Haikei" />
                <LinkURL href="https://tabbied.com/" name="Tabbied" />
                <LinkURL href="https://www.visiwig.com/" name="Visiwig" />
                <LinkURL href="https://undraw.co/" name="unDraw" />
                <LinkURL
                    href="https://doodleipsum.com/"
                    name="Doodle Ipsum"
                    new={false}
                />
            </div>
        </>
    );
}

function OtherTab() {
    const [activeTab, setActiveTab] = useState("universe");

    return (
        <>
            <h2>Other</h2>

            <div className="links_with_box">
                <div className="links">
                    <TabNavItem
                        title="Universe"
                        id="universe"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        className="link"
                    />
                    <h3>Guides</h3>
                    <TabNavItem
                        title="web.dev"
                        id="web_dev"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                    <TabNavItem
                        title="Material Design"
                        id="material_des"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        is_new={true}
                    />
                    <TabNavItem
                        title="Mozilla.org"
                        id="mozilla"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        is_new={true}
                    />

                    <h3>Ideas</h3>
                    <TabNavItem
                        title="Visual Journal"
                        id="visual_journal"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        is_new={true}
                    />

                </div>
                <div className="card">
                    <TabContent id="universe" activeTab={activeTab}>
                        <h3>Universe</h3>
                        <p>Universe is a collection of open source, free html and css web components.</p>
                        <div className="links"><LinkURL href="https://uiverse.io/" name="Visit Universe" /></div>
                    </TabContent>

                    <TabContent id="web_dev" activeTab={activeTab}>
                        <h3>web.dev</h3>
                        <p>web.dev is a google run website that helps teach designers the fundamentals of web development.</p>
                        <div className="links"><LinkURL href="https://web.dev/" name="Visit web.dev" /></div>
                    </TabContent>

                    <TabContent id="material_des" activeTab={activeTab}>
                        <h3>Material Design</h3>
                        <p>Material Design is a google run website that helps teach designers the fundamentals of design especially for the web.</p>
                        <div className="links"><LinkURL href="https://m3.material.io/" name="Visit Material Design" /></div>
                    </TabContent>

                    <TabContent id="mozilla" activeTab={activeTab}>
                        <h3>Mozilla.org</h3>
                        <p>Mozilla.org" is run by the creators of firefox. The aim is to have documentation for all major web browsers features in one place.</p>
                        <div className="links"><LinkURL href="https://developer.mozilla.org/" name="Visit mozilla.org" /></div>
                    </TabContent>

                    <TabContent id="visual_journal" activeTab={activeTab}>
                        <h3>Visual Journal</h3>
                        <p>Visual Journal is a great place to go to when needing inspiration for designs.</p>
                        <div className="links"><LinkURL href="https://visualjournal.it/" name="Visit Visual Journal" /></div>
                    </TabContent>
                </div>
            </div>
        </>
    );

}

export {
    IconTab,
    AnimationTab,
    ColorTab,
    FontTab,
    VectorGraphicTab,
    ImageryTab,
    OtherTab,
};
