import "./tabs.css";

const NewTag = () => {
    return (
        <span className="new_tag">
            <div>NEW</div>
        </span>
    );
};

export const TabNavItem = ({ id, title, activeTab, setActiveTab, is_new }) => {
    const handleClick = () => {
        setActiveTab(id);
    };

    return (
        <button
            onClick={handleClick}
            className={activeTab === id ? "tab active" : "tab "}
        >
            {title}
            {is_new === true ? <NewTag /> : ""}
        </button>
    );
};

export const TabContent = ({ id, activeTab, children }) => {
    return activeTab === id ? (
        <div className="TabContent">{children}</div>
    ) : null;
};
