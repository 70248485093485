import { LinkURL } from "../components/links";

export default function AllProjects() {
    return (
        <div>
            <h1>All Projects</h1>


            <h2>Rad Games</h2>
            <p>
                Rad Games is a fun game project i still work on from time to
                time. It was the first big project i started especially in
                JavaScript. It has many fun different games that i have made
                over time.
            </p>
            <div className="links">
                <LinkURL href="https://radgames.radquincy.com/" name="Visit" />

                {/* <Link href="./" name="View Code" /> */}
            </div>

            <h2>Workbook</h2>
            <p>
                Workbook is a project that uses markdown syntax to save and view
                files. Files over the internet allows for much easier sharing
                and accessibility to users. Currently has been delayed Indefinitely.
            </p>
            {/* <div className="links">
                <LinkURL href="https://workbook.radquincy.com/" name="Visit" />

                <Link href="./" name="View Code" />
            </div> */}

            <h2 > The Shares Game</h2 >
            <p>
                A funny Old Project and my first game. Its coded in PHP, HTML
                and CSS and requires a database to run. Its a game were you buy
                and sell shares and need to make sure you don't loose.
            </p>
            <div className="links">
                <LinkURL
                    href="https://github.com/radquincy/The-Share-Game"
                    name="View Code"
                />
            </div>
        </div >
    );
}
