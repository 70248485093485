import { TabContent, TabNavItem } from "../../components/tabs";
import { useState } from "react";
import {
    IconTab,
    AnimationTab,
    ColorTab,
    FontTab,
    // VectorGraphicTab,
    ImageryTab,
    OtherTab,
} from "./resource_tabs";

export default function Resources() {
    const [activeTab, setActiveTab] = useState("tabIcon");

    return (
        <>
            <h1>Resources</h1>
            <p>
                The resources is to help developers and designers have an easier
                time when developing and designing especially for the web. Hopefully you find these
                helpful in some way. I try to keep these resources are all free or have a free option.
                None of these resources are not related to this website itself.
            </p>
            <div className="tab_group">
                <TabNavItem
                    title="Icons"
                    id="tabIcon"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <TabNavItem
                    title="CSS"
                    id="tabAnimation"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <TabNavItem
                    title="Colors"
                    id="tabColor"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <TabNavItem
                    title="Fonts"
                    id="tabFont"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />

                <TabNavItem
                    title="Imagery"
                    id="tabImage"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <TabNavItem
                    title="Other"
                    id="tabOther"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </div>
            <TabContent id="tabIcon" activeTab={activeTab}>
                <IconTab />
            </TabContent>

            <TabContent id="tabAnimation" activeTab={activeTab}>
                <AnimationTab />
            </TabContent>

            <TabContent id="tabColor" activeTab={activeTab}>
                <ColorTab />
            </TabContent>

            <TabContent id="tabFont" activeTab={activeTab}>
                <FontTab />
            </TabContent>

            <TabContent id="tabImage" activeTab={activeTab}>
                <ImageryTab />
            </TabContent>

            <TabContent id="tabOther" activeTab={activeTab}>
                <OtherTab />
            </TabContent>
        </>
    );
}
