function IconLink(pass) {
    let icon = "";
    if (pass.icon_type === "ion-icon") {
        icon = [<ion-icon name={pass.icon} key={pass.id}></ion-icon>];
    } else if (pass.type === "ion-icon") {
        icon = [<i className={"bi " + pass.icon} key={pass.id}></i>];
    }
    return (
        <a
            className="icon_button"
            href={pass.url}
            target="_blank"
            rel="noopener noreferrer"
            key={pass.id}
        >
            {icon}
        </a>
    );
}

function IconTextLink(pass) {
    let icon = "";
    if (pass.icon_type === "ion-icon") {
        icon = [<ion-icon name={pass.icon}></ion-icon>];
    } else if (pass.type === "ion-icon") {
        icon = [<i className={"bi " + pass.icon}></i>];
    }
    return (
        <a
            className="icon_button"
            href={pass.url}
            target="_blank"
            rel="noopener noreferrer"
        >
            {icon}
        </a>
    );
}


function LinkURL(pass) {
    return (
        <a target="_blank" rel="noopener noreferrer" href={pass.href}>
            <div>
                {pass.name}
            </div>
        </a>
    );
}

export { IconLink, LinkURL, IconTextLink };
