import React, { useEffect, useCallback, useState } from "react";

import { IconLink } from "./links";
import { Link } from "react-router-dom";

import ThemeToggleBtn from "../assets/themes";

const SideBar = (props) => {
    return (
        <div className={`sidebar ${props.show ? "open" : "closed"}`}>
            <Link to="/" style={{ textDecoration: "none" }}>
                <i className="bi bi-house"></i>
                <button>Home</button>
            </Link>

            <Link to="/resources" style={{ textDecoration: "none" }}>
                <i className="bi bi-box-seam"></i>
                <button>Resources</button>
            </Link>

            <Link to="/all_projects" style={{ textDecoration: "none" }}>
                <i className="bi bi-journal-code"></i>
                <button>All Projects</button>
            </Link>

            <Link to="/whats_next" style={{ textDecoration: "none" }}>
                <i className="bi bi-patch-question"></i>
                <button>Whats Next</button>
            </Link>

            <Link to="/info" style={{ textDecoration: "none" }}>
                <i className="bi bi-info-circle"></i>
                <button>Info</button>
            </Link>
            {/* <Link to="/archive" style={{ textDecoration: "none" }}>
				<i className="bi bi-archive"></i>
				<button>The Archive</button>
			</Link> */}
        </div>
    );
};

function TopBar() {
    const [show, setShow] = useState(false);

    const closeOnEscapeKeyDown = useCallback(
        (e) => {
            if ((e.charCode || e.keyCode) === 27) {
                setShow((show) => (show === true ? false : true));
            }
        },
        [setShow]
    );
    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, [closeOnEscapeKeyDown]);

    return (
        <div className="top_bar">
            <button
                className="icon_button"
                onClick={() => {
                    setShow((show) => (show === true ? false : true));
                }}
            >
                <i className="bi bi-list"></i>
            </button>

            <div className="icon_right_group">
                <ThemeToggleBtn />

                <div className="break"></div>
                <IconLink
                    url="https://github.com/radquincy"
                    icon="logo-github"
                    icon_type="ion-icon"
                    id="1"
                />
                <IconLink
                    url="https://codepen.io/rad_quincy"
                    icon="logo-codepen"
                    icon_type="ion-icon"
                    id="2"
                />
                <IconLink
                    url="https://stackoverflow.com/users/21669239"
                    icon="logo-stackoverflow"
                    icon_type="ion-icon"
                    id="3"
                />
            </div>

            <SideBar onClose={() => setShow(false)} show={show} />

            <div
                onClick={() => {
                    setShow(false);
                }}
                className={`sidebarBox ${show ? "openBox" : "closedBox"}`}
            ></div>
        </div>
    );
}

export { TopBar };
