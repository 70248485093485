import { LinkURL } from "../components/links";

export default function Resources() {
	return (
		<div>
			<h1>Info</h1>
			<p>Developed By Rad_Quincy</p>
			<p>Using React Js and Scss.</p>

			<p>
				This is a personal project of mine that i like to work on semi
				consistently, alongside my other projects.
			</p>

			<br />

			<div className="links">
				<LinkURL href="https://react.dev/" name="React JS" />
				<LinkURL href="https://sass-lang.com/" name="Sass" />
			</div>
		</div>
	);
}
