import "./progress_bar.css";

export default function ProgressBar(pass) {
	return (
		<div className="progress_bar">
			<div className="options">
				<p className="from">{pass.from}</p>
				<p className="to">{pass.to}</p>
			</div>

			<div className="bar">
				<div
					className="bar_fill"
					style={{ width: pass.progress + "%" }}
				></div>
			</div>
		</div>
	);
}
